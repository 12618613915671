.metrics-card {
  &:hover {
    transition: all 100ms ease-in-out;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
    cursor: pointer;
  }
  &:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: -500px;
    width: 100%;
    height: 100%;
    background: #f4f6f8;
    transition: all 0.4s ease;
  }
  &:hover:before {
    bottom: 0px;
  }
  &:hover img {
    position: absolute;
    border-radius: 100%;
    // transform: scale(1.5);
  }
}

.count-subtitle {
  font-size: 0.7em;
}
.statistics-data {
  table tr:hover {
    background-color: rgba(145, 158, 171, 0.08);
    cursor: pointer;
  }
}
