.tooltip {
	margin-left: 10px;
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 200px;
	text-align: center;
	padding: 5px 5px;
	border-radius: 6px;
	border: 1px solid #adb5bd;
	position: absolute;
	z-index: 99999;
	top: -50%;
	left: 105%;
	background-color: #fafafa;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	color: #000;
}

.info-img {
	height: 35px;
	cursor: help;
}
