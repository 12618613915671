.text-Color
{
    color:'#000';
}
.text-Color .MuiInputBase-input {
    color: #002f43;
  }
  
  .text-Color .MuiFormLabel-root {
    color: #002f43;
  }
  .textBoxsize .MuiSelect-select {
    color: #002f43; 
  }  