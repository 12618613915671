.billing-invoices-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.billing-invoices-payments-container {
		padding: 20px;
		border: 1px solid #c4c4c4;
		border-radius: 10px;

		.billing-invoices-payment-container {
			padding: 17px 0;
			border-top: 1px solid #c4c4c4;

			.billing-invoices-payment-card-name {
				display: flex;
				justify-content: space-between;
			}

			.billing-invoices-payment-card-info {
				margin-top: 20px;
				display: flex;
				flex-direction: column;

				img {
					display: block;
					height: 17px;
				}

				.billing-invoices-payment-preferred {
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						color: #299d4a;
						font-size: 18px;
						font-weight: bold;
					}

					img {
						display: block;
					}
				}
			}
		}

		.billing-invoices-add-payments-container {
			border-top: 1px solid #c4c4c4;

			.billing-invoices-add-payments {
				margin-top: 20px;
				border: 1px solid #c4c4c4;
				border-radius: 10px;
				padding: 10px;
				cursor: pointer;
			}
		}

		.billing-invoices-add-payments-modal {
			width: 600px;
			background-color: #fff;

			padding: 20px 30px;

			position: absolute;
			top: 20%;
			left: 50%;
			transform: translate(-50%, 0%);

			z-index: 1200;

			border-radius: 10px;
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);

			h3 {
				color: #000;
				font-size: 30px;
				text-align: center;
			}

			.billing-invoices-add-payments-modal-card-container {
				margin-top: 30px;
				padding: 20px 14px;
				border: 1px solid #c4c4c4;
				border-radius: 10px;
			}

			.billing-invoices-add-payments-modal-card-container2 {
				margin-top: 30px;
				padding: 0 20px;

				border: 1px solid #c4c4c4;
				border-radius: 10px;

				cursor: pointer;
			}

			.billing-invoices-add-payments-modal-inp-container {
				display: flex;
				column-gap: 30px;
			}

			.billing-invoices-add-payments-modal-btns-container {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				column-gap: 30px;

				button {
					height: 50px;
					width: 200px;
					border-radius: 10px;
					border: none;
					font-size: 18px;
					cursor: pointer;
				}

				.billing-invoices-add-payments-modal-btn1 {
					background-color: #727272;
					color: #fff;
				}

				.billing-invoices-add-payments-modal-btn2 {
					background-color: #002f43;
					color: #fff;
				}
			}
		}

		.billing-invoices-success-payments-modal {
			max-width: 550px;
			width: 30vw;
			background-color: #fff;

			padding: 20px 30px;

			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1300;

			border-radius: 10px;
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
			text-align: center;
		}

		.billing-invoices-upcoming-payments-container {
			border-top: 1px solid #c4c4c4;
			// border-bottom: 1px solid #c4c4c4;

			.billing-invoices-upcoming-payments-title {
				color: #000;
				font-weight: bold;
				margin-top: 20px;
			}

			p {
				color: #000;
			}
		}

		.billing-invoices-table-container {
			padding-bottom: 20px;
			border-top: 1px solid #c4c4c4;

			.billing-invoices-upcoming-payments-title {
				color: #000;
				font-weight: bold;
				margin-top: 20px;
			}
		}

		.billing-invoices-invoices-filters-container {
			display: flex;
			column-gap: 20px;

			border-top: 1px solid #c4c4c4;
			padding-top: 20px;

			.billing-invoices-invoices-filter-container {
				height: 40px;
				width: 285px;

				display: flex;
				align-items: center;

				border: 1px solid #c4c4c4;
				border-radius: 5px;

				input {
					flex-grow: 1;
					display: block;

					margin-left: 10px;

					font-size: 18px;

					border: none;
					background-color: transparent;

					&:focus {
						outline: none;
					}
				}

				button {
					display: block;
					height: 40px;
					width: 70px;
					background-color: #002f43;
					border: none;
					border-radius: 5px;
					cursor: pointer;

					img {
						margin: auto;
					}
				}
			}

			.billing-invoices-invoices-adv-filter-container {
				display: flex;
				justify-content: center;
				align-items: center;
				column-gap: 5px;

				width: 190px;
				height: 40px;

				border: 1px solid #c4c4c4;
				border-radius: 5px;
				cursor: pointer;

				p {
					font-size: 18px;
					color: #143f67;
				}
			}

			.billing-invoices-invoices-adv-btns-container {
				display: flex;
				column-gap: 20px;
			}

			button {
				height: 40px;
				width: 100px;
				border: none;
				border-radius: 5px;
				font-size: 18px;

				background-color: #002f43;
				color: #fff;

				cursor: pointer;
			}

			.billing-invoices-invoices-adv-filter-logo-btn {
				width: 130px;

				display: flex;
				justify-content: center;
				align-items: center;
				column-gap: 10px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		padding: 10px;

		.billing-invoices-payments-container {
			margin-top: 14px;

			.billing-invoices-add-payments-modal {
				width: 90%;
				background-color: #fff;
				padding: 20px 30px;

				.billing-invoices-add-payments-modal-card-container {
					margin-top: 20px;
					padding-right: 5px;
				}
			}

			.billing-invoices-success-payments-modal {
				width: 65vw;
			}

			.billing-invoices-invoices-filters-container {
				display: inline-block;

				.billing-invoices-invoices-filter-container {
					button {
						margin: 0;
					}
				}

				.billing-invoices-invoices-adv-filter-container {
					margin: auto;
					margin-top: 10px;
				}

				.billing-invoices-invoices-adv-filter-logo-btn {
					margin: auto;
					margin-top: 10px;
				}

				button {
					display: block;
					margin: auto;
					margin-top: 10px;
				}
			}

			.billing-invoices-invoices-table-container {
				overflow: scroll;
			}

			.billing-invoices-invoices-table-manager {
				display: block;
				padding: 0;

				p {
					text-align: center;
				}

				.billing-invoices-invoices-table-manager-page-num-select {
					display: block;
					margin: 10px auto;
				}

				.billing-invoices-invoices-table-manager-img-container {
					justify-content: center;
				}
			}
		}
	}
}
