.article {
  background:#222; 
    font-size: 19px;
  }
  .innerContent img{
    max-height: 350px;
    width: 75% !important;
    height: 90% !important;
    margin: auto !important;
    display: block;
  }
  .article ol{
    padding-left: 20px;
  }

  .article img {
    width: 100%;
    height: 100%;
  }
  
  .article p:empty {
    display: none;
  }
  
  .article a {
    color: white !important;
    text-decoration: underline !important;
  }
  
  .article h3 {
    margin-top: 20px;
    text-align: left;
  }
  
  
  .article li {
    font-size: 20px;
    overflow-wrap: anywhere;
    margin-top: 20px;
  }
  
  .article p {
    
    overflow-wrap: anywhere;
    margin-top: 20px;
  }
  
  .article h2 {
    margin-top: 30px;
    text-align: left;
  }
  
  .mainImg {
    height: auto;
    width: 100%;
  }
  
  .img2 {
    height: 200px;
    width: auto;
  }
  
  .img3 {
    height: 200px;
    width: auto;
  }
  
  .article .rowReverse {
    flex-direction: row-reverse;
  }
  
  .multiplesPContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  
  .h1 {
    font-size: var(--mobile-h2-fontsize);
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  .imgContainer {
    width: auto;
    position: relative;
  }
  
  .ulImg {
    width: 100%;
    height: auto;
  }