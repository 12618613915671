@import 'src/scss/variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10vh;
  background-color: $secondary-bg-color;
  border-radius: 15px;
  width: 75vw;
  padding: 7vw;
}

.logo {
  width: 40%;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1vh;
}

.description {
  width: 100%;
  color: $primary-text-color;
  text-align: center;
}

.description2 {
  color: $alternative-color;
}

.form {
  gap: 2vh;
}

.form input {
  color: $primary-text-color;
}

.title {
  color: $secondary-color;
  /* font-weight: $weight-500; */
}

.primaryBtn {
  color: $alternative-text-color;
  // background-color: $secondary-color;
  border-radius: 4px;
  text-transform: none;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.secondaryBtn {
  color: $primary-text-color;
  text-transform: none;
  padding: 0;
}

@media (min-width: 600px) {
	.wrapper {
		gap: 16vh;
		width: 50vw;
		padding: 6vh 4vw;
	}
	.form {
		gap: 4vh;
	}
	.logo {
		width: 30%;
	}
}

@media (min-width: 1200px) {
	.wrapper {
		gap: 16vh;
		width: 38vw;
		padding: 6vh 4vw;
	}
	.form {
		gap: 4vh;
	}
	.logo {
		width: 30%;
	}
}
