.welcome-view-container {
  @media screen and (max-width: 500px) {
    width: 80%;
    margin: auto;

    button {
      width: 90%;
    }
  }
}
