.otpform-container {
	width: 440px;
	margin: auto;

	.otpform-code-container {
		margin-top: 50px;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}

	@media screen and (max-width: 500px) {
		width: 100%;

		h2 {
			text-align: center;
		}
	}
}
