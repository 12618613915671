.signin-form-container {
  padding-bottom: 50px;

  .signin-chat {
    position: absolute;
    top: 45%;
    right: 0;
    background-color: #256fa5;
    padding: 10px 5px;
    border-radius: 5px;
    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
      background-color: #727272;
      transition: all 0.5s ease;
    }
  }

  .signin-form-btns-container {
    display: flex;
    justify-content: center;
    column-gap: 35px;
  }

  .signin-form-spacer {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .signin-form-btns-container {
      display: flex;
      justify-content: center;
      column-gap: 35px;
    }

    .signin-form-stepper-container {
      display: none;
    }

    .signin-form-spacer {
      display: block;
      height: 100px;
    }
  }
}
