.checkout-container {
	width: 1160px;
	margin: auto;

	.checkout-title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			color: #000;
			font-size: 30px;
		}

		p {
			color: #000;
			font-size: 18px;
		}

		span {
			color: #000;
			font-size: 26px;
			font-weight: 700;
		}
	}

	.checkout-cards-container {
		display: flex;
		justify-content: space-between;

		.checkout-card {
			width: 540px;

			.section-container {
				margin-top: 30px;

				.section-info-container {
					display: flex;
					align-items: center;
					column-gap: 10px;
				}

				h4 {
					color: #000;
					border-bottom: 1px solid #00000029;
					padding-bottom: 5px;
					margin-bottom: 20px;
				}
				p {
					color: #000;
				}

				.checkout-section-img-container {
					display: flex;
					align-items: center;
					column-gap: 30px;
					margin-top: 20px;

					img {
						display: block;
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		width: 80%;
		margin: auto;

		.checkout-title-container {
			display: inline-block;

			h3 {
				font-size: 26px;
			}

			p {
				font-size: 16px;
			}

			span {
				font-size: 18px;
				font-weight: 700;
			}
		}

		.checkout-cards-container {
			display: inline-block;

			.checkout-card {
				width: 100%;
			}
		}
	}
}
