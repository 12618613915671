.productitems {
    font-family: LatoRegular;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    padding: 9px 0px;
    text-transform: capitalize;
    color:"#002F43";
  }
  .productitemsweight {
    font-family: LatoRegular;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: right;
    padding: 9px 0px;
    text-transform: capitalize;
    color:"#002F43";
  }
  .boxfont {
    font-size: 8px;
    color: #aaaaaa;
  }
  .totaltext {
    font-family: LatoRegular;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
  }
  .totaltextPreview {
    font-family: LatoRegular;
    font-size: 14px;
    font-weight: 400;
    line-height: 10px;
  }
  .selectboxtext {
    font-family: LatoRegular;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-transform: capitalize;
  }
  .previewDialogTitle {
    font-family: LatoSemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  .textBoxsize {
    width: 300px;
    height: 50px;
  }
  
  .radiotext {
    display: flex;
    justify-content: space-between;
    font-size: "16px";
    font-family: LatoSemiBold;
    font-weight: 800;
    line-height: 19.2px;
    text-align: left;
  }
  .padding-space {
    padding: 20px;
  }
  .cancel-btn-customise {
    font-family: Whitney;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.42px;
    text-align: left;
    /* width: 130px; */
    height: 42px;
    border-radius: 10px;
    padding: 12px 42px;
  }
  
  .return-btn-customise {
    font-family: Whitney;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    height: 42px;
    border-radius: 10px;
    padding: 12px 30px;
  }
  .Okay-btn-customise {
    font-family: Whitney;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    height: 42px;
    border-radius: 10px;
    padding: 12px 46px 11px 46px;
  }
  .colorBlack .MuiInputBase-root {
    color: black !important;
  }
  
  .colorBlack {
    color: black !important;
  }
  .errorMessageText {
    text-align: center;
    font-size: 14px;
    color: #D32F2F;
  }