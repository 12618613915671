.billing-container {
  .billing-card-container {
    width: 600px;

    margin: 30px auto;

    border: 1px solid #dee2e6;
    border-radius: 10px;

    img {
      display: block;
      margin: 10px auto;
    }
  }

  .billing-inps-container {
    width: 600px;
    margin: 30px auto;

    .billing-inp-container {
      display: flex;
      column-gap: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 90%;
    margin: auto;

    .billing-card-container {
      width: 100%;
    }

    .billing-inps-container {
      width: 100%;

      .billing-inp-container {
        column-gap: 10px;
      }
    }
  }
}
