.manage-container {
	width: 600px;
	margin: auto;
	position: relative;

	.manage-img-container {
		margin: 30px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		img {
			height: 60px;
			padding: 10px 20px;
			border: 1px solid transparent;
			border-radius: 5px;
			transition: all 0.5s ease;
			cursor: pointer;
		}

		.manage-img2 {
			height: 100px;
		}
	}

	@media screen and (max-width: 500px) {
		width: 80%;

		.manage-img-container {
			display: block;
			margin: auto;

			img {
				display: block;
				margin: auto;
				margin-top: 20px;
			}
		}
	}
}
