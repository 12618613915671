.formLabel {
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.inputStyle {
    border: 1px solid #1C272A33;
    width: 100%;
    outline: none;
    height: 40px;
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
}

.spanText {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    color: #AAAAAA;

}
.textAreaStyle{
    border: 1px solid #1C272A33;
    width: 100%;
    outline: none;
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    resize: none;
    font-family: "LatoMedium";
}

.text-error{
    font-size: 13px;
    color: red;
}

/* Add this to your CSS file */
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    width: 300px;
    height: 300px;
    text-align: center;
    position: relative;
    transition: border 0.3s ease;
  }
  
  .dropzone.dragging {
    border-color: #66cc66; /* Change border color when dragging */
  }
  
  .dropzone img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .dropzone p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  .highlighted-row {
    background-color: #f0f8ff; 
    transition: background-color 0.3s;
  }
  .nopadding{
    padding: 0px;
  }