.shopify-form-container {
	.manage-img {
		display: block;
		margin: 20px auto;
	}

	p {
		color: #000;
		font-size: 18px;
		text-align: center;
		margin-top: 10px;
	}

	.shopify-form-step-container {
		width: 550px;
		margin: auto;

		.shopify-form-step {
			display: flex;
			align-items: center;
			column-gap: 30px;

			.shopify-form-txt {
				text-align: left;
			}

			.shopify-form-index {
				height: 40px;
				min-width: 40px;
				padding: 4px 0;

				background-color: #002f43;
				border-radius: 50%;
				color: #fff;
			}
		}

		.shopify-form-inp {
			width: 100%;
			height: 50px;
			margin-top: 10px;
			padding: 0 10px;

			border: 1px solid #adb5bd;
			border-radius: 5px;
			font-size: 18px;
		}
	}

	@media screen and (max-width: 500px) {
		.shopify-form-step-container {
			width: 100%;
			margin: auto;
		}
	}
}
