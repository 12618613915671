.ql-editor {
    color: black; 
    background-color: white; 
}
.txt-color{
    color:black;
}
/* .ql-align-center {
    text-align: center;
    a {
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none !important;
      cursor: pointer;
    }
  } */
  